import { preloadImages as mentalPreloadImages } from '@mentalgrowth/web_utils';

type TpImagePath = 'images' | 'icons';

const getImageUrl = (path: TpImagePath = 'images') => `/${path}/`;

export const IMAGE_SOURCE = getImageUrl();
export const ICON_SOURCE = getImageUrl('icons');

export const preloadImages = mentalPreloadImages;
