import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAnalyticsDebugger } from '@features/Analytics/hooks/useAnalytics';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { ALL_VARIANTS } from 'src/constants/variants';
import { groupBy } from 'src/utils';

import { useFeatures } from '@features/FeatureToggle';
import { useRouter } from 'next/router';
import styles from './debug-ui.module.scss';

type TpSource = 'PT' | 'GA' | 'FB';

type Event = {
  name: string;
  date: string;
  params?: Record<string, any>;
};

export const DebugUi = observer(() => {
  const [activeTab, setActiveTab] = useState<TpSource>('GA');
  const [hasMounted, setHasMounted] = useState(false);
  const [showTranslated, setShowTranslated] = useState(false);

  const {
    authStore: { variant: variantFromStorage },
    analyticsStore: { abTests },
  } = useStores();
  const features = useFeatures();
  const { query } = useRouter();
  const { events, clearEvents, eventsNumberVisible, setEventsNumberVisible } =
    useAnalyticsDebugger();

  const [activeVariant, setActiveVariant] = useState(variantFromStorage);

  const toggleEventsNumber = useCallback(
    () => setEventsNumberVisible(!eventsNumberVisible),
    [eventsNumberVisible, setEventsNumberVisible],
  );

  const groupedEvents = useMemo(() => groupBy(events, 'source'), [events]);
  const filteredEvents = useMemo(
    () => groupedEvents[activeTab] || [],
    [activeTab, groupedEvents],
  );

  const handleTabChange = useCallback((tab: TpSource) => setActiveTab(tab), []);

  const handleVariantChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setActiveVariant(event.target.value);
    },
    [],
  );

  const sortedVariants = useMemo(
    () =>
      [...ALL_VARIANTS].sort((a, b) =>
        a.variant_name.localeCompare(b.variant_name),
      ),
    [],
  );

  const abTestDisplay = useMemo(
    () =>
      abTests && abTests.length > 0 ? (
        <div className={styles.abTestSection}>
          <h3 className={styles.sectionTitle}>Active A/B Tests:</h3>
          {abTests.map((test, index) => (
            <p key={index}>{`${test.test_name} : ${test.case_name}`}</p>
          ))}
        </div>
      ) : null,
    [abTests],
  );

  const applyTranslationStyles = useCallback(() => {
    const translatedElems = document.querySelectorAll('[data-trans]');
    for (const elem of translatedElems) {
      (elem as HTMLElement).style.backgroundColor = 'rgba(255, 255, 0, 0.5)'; // Жёлтая подсветка
    }
  }, []);

  const removeTranslationStyles = useCallback(() => {
    const translatedElems = document.querySelectorAll('[data-trans]');
    for (const elem of translatedElems) {
      (elem as HTMLElement).style.backgroundColor = '';
    }
  }, []);

  useEffect(() => {
    if (query['reset'] === undefined && query['hard_reset'] === undefined) {
      setHasMounted(true);
      setActiveVariant(variantFromStorage);
    }
  }, [query, variantFromStorage]);

  useEffect(() => {
    if (showTranslated) {
      applyTranslationStyles();

      const observer = new MutationObserver(() => {
        applyTranslationStyles();
      });

      observer.observe(document.body, { childList: true, subtree: true });

      return () => observer.disconnect();
    } else {
      removeTranslationStyles();
    }
    return;
  }, [showTranslated, applyTranslationStyles, removeTranslationStyles]);

  const renderEvents = useMemo(
    () =>
      filteredEvents.map(({ name, date, params }: Event) => (
        <div key={`${name}-${date}`} className={styles.eventItem}>
          <span className={styles.eventName}>{name}</span>
          {params && (
            <span className={styles.eventParams}>
              {JSON.stringify(params, null, 2)}
            </span>
          )}
          <span className={styles.eventDate}>
            {new Date(date).toISOString()}
          </span>
        </div>
      )),
    [filteredEvents],
  );

  return (
    <div
      className={classNames(styles.host, {
        [styles.expanded]: eventsNumberVisible,
      })}
      aria-hidden="true"
    >
      <div className={styles.row}>
        <UiKitButton
          className={styles.smallButton}
          onClick={toggleEventsNumber}
        >
          {eventsNumberVisible ? 'off' : 'on'}
        </UiKitButton>

        {eventsNumberVisible && (
          <>
            {hasMounted && (
              <select
                className={styles.select}
                name="variant-select"
                id="select"
                onChange={handleVariantChange}
                value={
                  sortedVariants.some(
                    ({ variant_name }) => variant_name === activeVariant,
                  )
                    ? activeVariant
                    : 'default'
                }
              >
                {sortedVariants.map(({ variant_name }) => (
                  <option key={variant_name} value={variant_name}>
                    {variant_name}
                  </option>
                ))}
              </select>
            )}
            <UiKitButton
              className={styles.smallButton}
              onClick={() =>
                window.location.replace(
                  `quiz?hard_reset=1&variant=${activeVariant}`,
                )
              }
            >
              Reset
            </UiKitButton>
            <UiKitButton className={styles.smallButton} onClick={clearEvents}>
              Clear
            </UiKitButton>
            {Object.keys(groupedEvents).map((key) => (
              <UiKitButton
                key={key}
                className={styles.smallButton}
                style={{
                  background: activeTab === key ? '#0e0e0d' : '#ff485c',
                }}
                onClick={() => handleTabChange(key as TpSource)}
              >
                {key}
              </UiKitButton>
            ))}
            <UiKitButton
              className={styles.smallButton}
              onClick={() => setShowTranslated((prev) => !prev)}
            >
              {showTranslated ? 'Hide' : 'Show'} Translated
            </UiKitButton>
          </>
        )}
      </div>

      {eventsNumberVisible && (
        <>
          <div className={styles.featuresRow}>
            <h3 className={styles.sectionTitle}>Active features:</h3>
            <pre>{JSON.stringify(features, null, 2)}</pre>
          </div>
          {hasMounted && abTestDisplay}
          <div>
            <h3 className={styles.sectionTitle}>Events:</h3>
            <div className={styles.eventsSection}>{renderEvents}</div>
          </div>
        </>
      )}
    </div>
  );
});
